module services {
    export module customs {
        export class accountPeriodService implements interfaces.customs.IAccountPeriodService {
            static $inject = ["$resource", "ENV", "$http", "$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, private $timeout: ng.ITimeoutService, public generalService: interfaces.applicationcore.IGeneralService) {
            }

            getList(): ng.resource.IResourceClass<interfaces.customs.IAccountPeriodDisplay> {
                return this.$resource<interfaces.customs.IAccountPeriodDisplay>(this.ENV.DSP_URL + "AccountPeriod/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        params: {
                            numberRecords: 25,
                            pageNumber: 1,
                        }
                    }
                });
            }

            getPostingPeriodsLines(): ng.resource.IResourceClass<interfaces.customs.IAccountPeriodDisplay> {
                var viewDetail: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                }
                return this.$resource<interfaces.customs.IAccountPeriodDisplay>(this.ENV.DSP_URL + "AccountPeriod/GetPostingPeriodsLines", {
                    accountId: "@accountId",
                }, {
                    query: viewDetail
                });
            }

            getAccountPeriodDetail(periodId: number, accountId: number): ng.resource.IResourceClass<interfaces.customs.IAccountPeriod> {
                return this.$resource<interfaces.customs.IAccountPeriod>(this.ENV.DSP_URL + "AccountPeriod/GetAccountPeriodDetail", {
                    periodId: periodId,
                    accountId: accountId
                }, {
                    query: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }

            getAccountPeriodsForDropdown(accountId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "AccountPeriod/GetForDropdown", {
                    accountId: accountId
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "AccountPeriod/Save");
            }

        }
    }

    angular.module("app").service("accountPeriodService", services.customs.accountPeriodService);
}